<template>
  <div class="service-area pt--70 pb--50 bg_color--1">
    <v-row no-gutters>
      <v-col md="8" offset-md="2" cols="12">
        <div :class="classTitle" class="section-title app-form-info">
          <h2 class="text-center heading-title primary--text">Apply online</h2>
          <p class="description">
            <b>Care Worker Job:</b><br />
            <span>
              To provide a Service of Care to service users to enable them to
              lead as independent a lifestyle as possible in their own homes.
              This will entail providing person-centered care to individuals
              through the use of a Care & Support Plan.
            </span>
            <br />
            <br />
            <b>Support Worker Job:</b><br />
            <span>
              Opportunities have arisen for roles for support workers to work
              with individuals presenting with mental health support needs and
              learning disabilities including autism who have tenancies in
              Calderdale in West Yorkshire.
            </span>
            <br />
            <br />
            <b>International Recruitment:</b><br />
            <span>
              RHS24 has a licence to recruit skilled workers from some countries abroad.
            </span>
          </p>
          <p class="description">
            Complete the job application form below and make sure all questions
            marked as required are filled-in.<br />
            A copy of the completed online application form will be sent to your
            e-mail address, so please check that your e-mail address is
            correct.<br />
            Click the submit button when you have finished completing the
            form.<br />
            We will review your application and contact you within three working
            days.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="service-main-wrapper bg-white--service" no-gutters>
      <v-col md="8" offset-md="2" cols="12">
        <ApplicationForm />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ApplicationForm from "../components/ApplicationForm";
export default {
  components: {
    ApplicationForm,
  },
  computed: {
    classTitle() {
      return this.$vuetify.breakpoint.smAndDown ? "app-title" : "";
    },
  },
};
</script>
<style>
.app-form-info {
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background-color: #e6e6e6;
}
.app-title {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
